import React, { FC, useState } from 'react'

import { DatePickerMode, DatePickerProps } from './types'
import Modal from './modal'
import Widget from './widget'
import { useCalendarMonths, useDatePickerType, useEnabledDates } from './hooks'

const DatePicker: FC<DatePickerProps> = ({
    mode,
    modalComponent,
    widgetComponent,
    modalCalendarContainerComponent,
    ...restProps
}) => {
    const { startDate, endDate, disabledByDefault, enabledDates } = restProps

    const datePickerType = useDatePickerType({ endDate })
    const { calendarMonths, setCalendarMonths, startMonth } = useCalendarMonths({ startDate, endDate, datePickerType })
    const enabledDatesSet = useEnabledDates({ disabledByDefault, enabledDates })
    const [currentlyHoveredDate, setCurrentlyHoveredDate] = useState<Date | null>(null)

    const showModal = mode === DatePickerMode.MODAL

    if (showModal) {
        return (
            <Modal
                {...restProps}
                component={modalComponent}
                calendarContainerComponent={modalCalendarContainerComponent}
                datePickerType={datePickerType}
                calendarMonths={calendarMonths}
                setCalendarMonths={setCalendarMonths}
                enabledDatesSet={enabledDatesSet}
                currentlyHoveredDate={currentlyHoveredDate}
                setCurrentlyHoveredDate={setCurrentlyHoveredDate}
            />
        )
    }

    return (
        <Widget
            {...restProps}
            component={widgetComponent}
            datePickerType={datePickerType}
            calendarMonths={calendarMonths}
            setCalendarMonths={setCalendarMonths}
            startMonth={startMonth}
            enabledDatesSet={enabledDatesSet}
            currentlyHoveredDate={currentlyHoveredDate}
            setCurrentlyHoveredDate={setCurrentlyHoveredDate}
        />
    )
}

export default DatePicker
