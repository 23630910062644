import styled, { css } from 'styled-components'

import { CalendarGrid } from './styles'

interface MonthCalendarGridProps {
    startAt?: number
}

export const MonthCalendarGrid = styled(CalendarGrid)<MonthCalendarGridProps>(
    ({ startAt }) => css`
        & > :first-child {
            grid-column: ${startAt ?? 1};
        }
    `,
)
