import React, { FC, memo, useCallback } from 'react'

import { WeekDayCell, WeekDayCellContainer } from './calendar-week-day-cell-styles'
import { useDateFormatter } from '../../../hooks/date'

interface Props {
    day: Date
    disabled?: boolean
    isSelected: boolean
    isWithinRange: boolean
    isFirstWithinRange: boolean
    isLastWithinRange: boolean
    onHover: (date: Date | null) => void
    onClick: (date: Date) => void
    loading?: boolean
}

const CalendarWeekDayCell: FC<Props> = ({
    day,
    disabled,
    isSelected,
    isWithinRange,
    isFirstWithinRange,
    isLastWithinRange,
    onClick,
    onHover,
    loading,
}) => {
    const { format } = useDateFormatter()

    const _handleMouseEnter = useCallback(() => onHover(day), [onHover, day])
    const _handleMouseLeave = useCallback(() => onHover(null), [onHover])
    const _handleClick = useCallback(() => onClick(day), [onClick, day])

    return (
        <WeekDayCellContainer
            isWithinRange={isWithinRange}
            isFirst={isFirstWithinRange}
            isLast={isLastWithinRange}
            onMouseEnter={_handleMouseEnter}
            onMouseLeave={_handleMouseLeave}
            onClick={_handleClick}
            disabled={disabled}
        >
            <WeekDayCell dateTime={format(day, 'yyyy-MM-dd')} isSelected={isSelected} loading={loading}>
                {format(day, 'd')}
            </WeekDayCell>
        </WeekDayCellContainer>
    )
}

export default memo(CalendarWeekDayCell)
