type GetId<T> = (value: T) => string | number

type Key<T> = ReturnType<GetId<T>>

class HashSet<T extends object> {
    private readonly _getId: GetId<T>
    private readonly _values: Map<Key<T>, T>
    private readonly _valuesSet: Set<T>

    constructor(values: readonly T[], getId: GetId<T> = value => value.toString?.()) {
        this._getId = getId
        this._values = values.reduce((accumulatedValues, currentValue) => {
            accumulatedValues.set(getId(currentValue), currentValue)
            return accumulatedValues
        }, new Map<Key<T>, T>())
        this._valuesSet = new Set(this._values.values())
    }

    add(value: T): this {
        this._values.set(this._getId(value), value)
        return this
    }

    clear() {
        this._values.clear()
    }

    delete(value: T): boolean {
        return this._values.delete(this._getId(value))
    }

    forEach(callbackFn: (value: T, value2: T, set: Set<T>) => void, thisArg?: any) {
        /* eslint-disable-next-line unicorn/no-array-callback-reference,unicorn/no-array-method-this-argument */
        this._valuesSet.forEach(callbackFn, thisArg)
    }

    has(value: T): boolean {
        return this._values.has(this._getId(value))
    }
}

export default HashSet
