import styled, { css } from 'styled-components'

import { FullScreenModalHeader } from '../../../../common/modal/full-screen-modal'

export const ModalHeaderContainer = styled(FullScreenModalHeader)`
    padding-bottom: 0;
    user-select: none;
`

export const DateInputsContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: ${theme.spacing.medium};
    `,
)

interface DateInputLabelProps {
    isActive?: boolean
}

export const DateInputLabel = styled.label<DateInputLabelProps>(
    ({ theme, isActive }) => css`
        display: flex;
        flex-direction: column;
        font: ${theme.typography.font.lato.weight.bold} 0.6875rem / ${theme.typography.font.size.medium}
            ${theme.typography.font.family.latoVerdana};
        letter-spacing: 0.025rem;
        color: ${theme.colors.tfwBlackOpacity50};
        text-transform: uppercase;
        padding-bottom: ${theme.spacing.medium};
        /* Give a transparent border in case of inactive, so as to maintain height. */
        border-bottom: ${theme.typography.border.width.xxThin} solid
            ${isActive ? theme.colors.tfwBlue12 : 'transparent'};
        max-width: 50%;

        &:not(:first-child):last-child {
            align-items: flex-end;
        }
    `,
)

export const DateInput = styled.input.attrs({ readOnly: true, 'aria-readonly': false })(
    ({ theme }) => css`
        width: ${theme.layout.fill};
        font: ${theme.typography.font.lato.weight.bold} ${theme.typography.font.size.medium} /
            ${theme.typography.font.size.xLarge} ${theme.typography.font.family.latoVerdana};
        padding: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        user-select: none;

        ${DateInputLabel}:not(:first-child):last-child & {
            text-align: right;
        }
    `,
)
