import styled, { css } from 'styled-components'

import { DatePickerPrompt } from './styles'

export const DatePickerModalPrompt = styled(DatePickerPrompt)(
    ({ theme }) => css`
        flex-shrink: 0;
        margin: 0 ${theme.spacing.xSmall};
    `,
)
