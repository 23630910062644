import styled, { css, StyledComponent } from 'styled-components'

import { DefaultTheme } from '../../../styled-components'
import { DatePickerPrompt } from './styles'

import ChevronLeft from '../../../assets/icons/chevron-left.svg'
import ChevronRight from '../../../assets/icons/chevron-right.svg'
import { DatePickerAlignment } from './types'

export const WidgetContent = styled.div`
    display: flex;
    justify-content: space-between;
    user-select: none;
`

export const ArrowButton = styled.button.attrs({ type: 'button' })`
    &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
`

export interface CalendarMonthContainerProps {
    isWide: boolean
    alignmentPosition?: DatePickerAlignment
}

export const CalendarMonthContainer = styled.div<CalendarMonthContainerProps>(
    ({ isWide }) => css`
        max-width: 22.5rem;
        width: 100%;

        ${isWide &&
        css`
            &:first-child ${ArrowButton}:last-child, &:last-child ${ArrowButton}:first-child {
                opacity: 0;
                pointer-events: none;
            }
        `};
    `,
)

export const CalendarCardHeaderContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: ${theme.spacing.xSmall};
    `,
)

export const CalendarCardHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ArrowLeft = styled(ChevronLeft as StyledComponent<'img', DefaultTheme>).attrs({
    width: '24px',
    height: '24px',
})(
    ({ theme }) => css`
        min-width: ${theme.layout.xSmall};
        min-height: ${theme.layout.xSmall};
        /* Remove the unnecessary height due to button height being greater than content height. */
        /* Read more: https://stackoverflow.com/a/45424887/8158281 */
        display: block;
    `,
)

export const ArrowRight = styled(ArrowLeft).attrs({ as: ChevronRight })``

export const MonthTitle = styled.strong(
    ({ theme }) => css`
        font-size: ${theme.typography.font.size.medium};
        line-height: ${theme.typography.font.size.xLarge};
    `,
)

interface DatePickerWidgetPromptProps {
    isCentered?: boolean
}

export const DatePickerWidgetPrompt = styled(DatePickerPrompt)<DatePickerWidgetPromptProps>(
    ({ isCentered, theme }) => css`
        user-select: none;

        ${isCentered
            ? css`
                  position: absolute;
                  top: 1.25rem;
                  left: 50%;
                  transform: translateX(-50%);
              `
            : css`
                  margin-left: ${theme.spacing.xSmall};
              `};
    `,
)
