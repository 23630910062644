import { addMonths, differenceInDays, eachMonthOfInterval, isFuture, isSameMonth, startOfMonth } from 'date-fns'

import { DatePickerType } from './types'
import { TranslationFunction } from '../../../common/types'

export const getDatePrompt = (
    datePickerType: DatePickerType,
    startDate: Date | null,
    endDate: Date | null | undefined,
    shortPrompt: boolean,
    t: TranslationFunction,
) => {
    if (datePickerType !== DatePickerType.DUAL_DATE) {
        return
    }

    const _defaultPrompt = shortPrompt ? '--' : t('datePickerPrompt')

    if (startDate && endDate) {
        const numNights = differenceInDays(endDate, startDate)
        return numNights > 0
            ? `${numNights} ${numNights === 1 ? t('night') : t('nights')}${
                  !shortPrompt ? ` / ${numNights + 1} ${t('days')}` : ''
              }`
            : _defaultPrompt
    }

    return _defaultPrompt
}

export const getCalendarMonths = (datePickerType: DatePickerType, startDate: Date | null, endDate?: Date | null) => {
    const _currentMonth = startOfMonth(new Date())

    // Compute calendar months and start month.
    let calendarMonths = [_currentMonth]
    let startMonth = _currentMonth

    if (startDate && isFuture(startDate)) {
        calendarMonths = eachMonthOfInterval({ start: _currentMonth, end: startDate })
        if (datePickerType === DatePickerType.DUAL_DATE && endDate && isFuture(endDate)) {
            calendarMonths = eachMonthOfInterval({ start: _currentMonth, end: endDate })
        }

        // Compute start month after computing calendar months in order to keep the correct reference.
        startMonth = calendarMonths.find(month => isSameMonth(month, startDate))!
    }
    if (datePickerType === DatePickerType.DUAL_DATE && calendarMonths.length === 1) {
        calendarMonths.push(addMonths(_currentMonth, 1))
    }

    return { calendarMonths, startMonth }
}
