import React, { FC, memo, useCallback, useRef } from 'react'

import FullScreenModal, { FullScreenModalFooter } from '../../../../common/modal/full-screen-modal'
import { SubmitButton } from '../styles'
import { useTranslation } from '../../../../../hooks/locale'
import { ChooseDate, DatePickerModalProps, DatePickerType } from '../../../../common/date-picker/types'
import CalendarDaysOfWeekGrid from '../../../../common/calendar/calendar-days-of-week-grid'
import DatePickerModalHeader from './modal-header'
import { DatePickerModalBody } from './modal-styles'
import { useChooseDate } from './hooks'
import { firstDayOfWeek } from '../../../../common/date-picker/constants'
import { DateInput, DateInputBackground } from './widget-styles'
import { InputError } from '../../../../common/input-styles'
import {
    getDatePickerLabel,
    getDatePickerStartDateLabel,
    getDatePickerEndDateLabel,
    getShouldShowEndDateLabel,
} from '../utils'
import { DatePickerIcon, NonSelectableWidgetInputContainer } from '../widget-styles'

const DatePickerModal: FC<DatePickerModalProps> = ({
    isOpen,
    onClose,
    onReset,
    onClear,
    onSubmit,
    startDate,
    endDate,
    datePickerType,
    datePrompt,
    children,
    error,
    onOpen,
    showInputInModal = false,
}) => {
    const { t } = useTranslation()

    const _handleReset = useCallback(() => {
        onReset()
        onClose()
    }, [onClose, onReset])

    const _handleSubmit = useCallback(() => {
        onSubmit()
        onClose()
    }, [onClose, onSubmit])

    const { chooseDate } = useChooseDate({ startDate, endDate, datePickerType })
    const DatePickerStartDateLabel = isOpen && chooseDate === ChooseDate.FIRST ? 'strong' : 'span'
    const DatePickerEndDateLabel = isOpen && chooseDate === ChooseDate.SECOND ? 'strong' : 'span'

    const _modalHeaderRef = useRef<HTMLDivElement>(null)

    return (
        <React.Fragment>
            {showInputInModal && (
                <NonSelectableWidgetInputContainer hasError={!!error}>
                    <DatePickerIcon alt={t('calendarIconText')} />
                    <DateInputBackground>
                        <DatePickerStartDateLabel>
                            {getDatePickerStartDateLabel({
                                startDate,
                                endDate,
                                startDatePlaceholder: t('checkIn'),
                                datePickerType,
                            })}
                        </DatePickerStartDateLabel>
                        {getShouldShowEndDateLabel(datePickerType, endDate, t('checkOut')) && (
                            <>
                                {' '}
                                -{' '}
                                <DatePickerEndDateLabel>
                                    {getDatePickerEndDateLabel({
                                        endDate: endDate!,
                                        endDatePlaceholder: t('checkOut'),
                                    })}
                                </DatePickerEndDateLabel>
                            </>
                        )}
                    </DateInputBackground>
                    <DateInput
                        value={getDatePickerLabel({
                            startDate,
                            endDate,
                            startDatePlaceholder: t('checkIn'),
                            endDatePlaceholder: t('checkOut'),
                            datePickerType,
                        })}
                        onClick={onOpen}
                    />
                    {!!error && <InputError>{error}</InputError>}
                </NonSelectableWidgetInputContainer>
            )}
            <FullScreenModal isOpen={isOpen} onRequestClose={_handleReset}>
                <DatePickerModalHeader
                    headerTitle={t('datePickerHeading')}
                    firstDate={startDate}
                    secondDate={endDate}
                    firstDateLabel={t('checkIn')}
                    secondDateLabel={t('checkOut')}
                    isFirstDateActive={chooseDate === ChooseDate.FIRST}
                    isSecondDateActive={chooseDate === ChooseDate.SECOND}
                    onClose={_handleReset}
                    onClear={onClear}
                    datePrompt={datePrompt}
                    datePickerType={datePickerType}
                    modalHeaderContainerRef={_modalHeaderRef}
                />
                <CalendarDaysOfWeekGrid
                    firstDayOfWeek={firstDayOfWeek}
                    // The following offset will only work if the ref element is placed BEFORE this element.
                    offset={_modalHeaderRef.current?.getBoundingClientRect().height}
                    zIndex={1000}
                />

                <DatePickerModalBody>{children}</DatePickerModalBody>

                {!!startDate && (datePickerType === DatePickerType.DUAL_DATE ? !!endDate : true) && (
                    <FullScreenModalFooter hasBorderTop zIndex={1000}>
                        <SubmitButton onClick={_handleSubmit}>{t('select')}</SubmitButton>
                    </FullScreenModalFooter>
                )}
            </FullScreenModal>
        </React.Fragment>
    )
}

export default memo(DatePickerModal)
