import React, { FC, memo, Ref } from 'react'

import { DatePickerIcon, NonSelectableWidgetInputContainer, WidgetContainer } from '../widget-styles'
import { useTranslation } from '../../../../../hooks/locale'
import { DateInput, DateInputBackground, DatePickerWidgetCard } from './widget-styles'
import {
    getDatePickerEndDateLabel,
    getDatePickerLabel,
    getDatePickerStartDateLabel,
    getShouldShowEndDateLabel,
} from '../utils'
import { useChooseDate } from './hooks'
import {
    CalendarsToShow,
    ChooseDate,
    DatePickerAlignment,
    DatePickerWidgetProps,
} from '../../../../common/date-picker/types'
import { InputError } from '../../../../common/input-styles'

const DatePickerWidget: FC<DatePickerWidgetProps> = ({
    isOpen,
    onOpen,
    startDate,
    endDate,
    datePickerType,
    error,
    inputRef,
    numberOfCalendarsToShow,
    children,
    alignmentPosition = DatePickerAlignment.CENTER,
}) => {
    const { t } = useTranslation()

    const { chooseDate } = useChooseDate({ startDate, endDate, datePickerType })
    const DatePickerStartDateLabel = isOpen && chooseDate === ChooseDate.FIRST ? 'strong' : 'span'
    const DatePickerEndDateLabel = isOpen && chooseDate === ChooseDate.SECOND ? 'strong' : 'span'

    const _isWide = numberOfCalendarsToShow === CalendarsToShow.DUAL

    return (
        <WidgetContainer ref={inputRef as Ref<HTMLDivElement>}>
            <NonSelectableWidgetInputContainer hasError={!!error}>
                <DatePickerIcon alt={t('calendarIconText')} />
                <DateInputBackground>
                    <DatePickerStartDateLabel>
                        {getDatePickerStartDateLabel({
                            startDate,
                            endDate,
                            startDatePlaceholder: t('checkIn'),
                            datePickerType,
                        })}
                    </DatePickerStartDateLabel>
                    {getShouldShowEndDateLabel(datePickerType, endDate, t('checkOut')) && (
                        <>
                            {' '}
                            -{' '}
                            <DatePickerEndDateLabel>
                                {getDatePickerEndDateLabel({ endDate: endDate!, endDatePlaceholder: t('checkOut') })}
                            </DatePickerEndDateLabel>
                        </>
                    )}
                </DateInputBackground>
                <DateInput
                    value={getDatePickerLabel({
                        startDate,
                        endDate,
                        startDatePlaceholder: t('checkIn'),
                        endDatePlaceholder: t('checkOut'),
                        datePickerType,
                    })}
                    onClick={onOpen}
                />
                {!!error && <InputError>{error}</InputError>}
            </NonSelectableWidgetInputContainer>

            <DatePickerWidgetCard isWide={_isWide} isOpen={isOpen} alignmentPosition={alignmentPosition}>
                {children}
            </DatePickerWidgetCard>
        </WidgetContainer>
    )
}

export default memo(DatePickerWidget)
