import React, { FC, memo, useMemo } from 'react'

import { CalendarGrid } from './styles'
import { getDaysOfWeek } from './utils'
import { useDateFormatter } from '../../../hooks/date'
import { Day } from './types'
import { CalendarHead, CalendarHeadProps, DayOfWeek } from './calendar-days-of-week-grid-styles'

interface Props extends Omit<CalendarHeadProps, '$offset'> {
    firstDayOfWeek?: Day
    offset?: number
}

const CalendarDaysOfWeekGrid: FC<Props> = ({ firstDayOfWeek, offset, zIndex }) => {
    const { dateLocale } = useDateFormatter()
    const _daysOfWeek = useMemo(() => getDaysOfWeek(firstDayOfWeek), [firstDayOfWeek])

    return (
        <CalendarHead $offset={offset} zIndex={zIndex}>
            <CalendarGrid>
                {_daysOfWeek.map(day => (
                    <DayOfWeek key={day}>{dateLocale.localize?.day(day, { width: 'short' })}</DayOfWeek>
                ))}
            </CalendarGrid>
        </CalendarHead>
    )
}

export default memo(CalendarDaysOfWeekGrid)
