import React, { FC, memo, MouseEventHandler, Ref } from 'react'

import { DateInput, DateInputLabel, DateInputsContainer, ModalHeaderContainer } from './modal-header-styles'
import { DatePickerModalPrompt } from '../../../../common/date-picker/modal-styles'
import { format } from '../../../../../hooks/date'
import { DatePickerType } from '../../../../common/date-picker/types'

interface Props {
    headerTitle: string
    firstDate: Date | null
    secondDate?: Date | null
    firstDateLabel: string
    secondDateLabel?: string
    isFirstDateActive: boolean
    isSecondDateActive?: boolean
    datePickerType: DatePickerType
    onClose: MouseEventHandler<HTMLButtonElement>
    onClear: MouseEventHandler<HTMLButtonElement>
    modalHeaderContainerRef?: Ref<HTMLDivElement>
    datePrompt?: string
}

const _getDateInputValue = (date?: Date | null) => (date ? format(date, 'dd MMM, yyyy') : '--')

const ModalHeader: FC<Props> = ({
    headerTitle,
    firstDate,
    secondDate,
    firstDateLabel,
    secondDateLabel,
    isFirstDateActive,
    isSecondDateActive,
    datePickerType,
    onClose,
    onClear,
    modalHeaderContainerRef,
    datePrompt,
}) => {
    return (
        <ModalHeaderContainer
            title={headerTitle}
            onClose={onClose}
            onClear={onClear}
            hasBorderBottom
            zIndex={1000}
            ref={modalHeaderContainerRef}
        >
            <DateInputsContainer>
                <DateInputLabel isActive={isFirstDateActive}>
                    {firstDateLabel}
                    <DateInput value={_getDateInputValue(firstDate)} />
                </DateInputLabel>
                {!!datePrompt && <DatePickerModalPrompt>{datePrompt}</DatePickerModalPrompt>}
                {datePickerType === DatePickerType.DUAL_DATE && (
                    <DateInputLabel isActive={isSecondDateActive}>
                        {secondDateLabel ?? ''}
                        <DateInput value={_getDateInputValue(secondDate)} />
                    </DateInputLabel>
                )}
            </DateInputsContainer>
        </ModalHeaderContainer>
    )
}

export default memo(ModalHeader)
