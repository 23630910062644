import { isSameDay, isAfter, isEqual, parseISO, isValid, differenceInDays, isSameYear } from 'date-fns'
import { FormatDateFunction } from '../common/types'

export const areDatesEqual = (firstDate?: Date | null, secondDate?: Date | null) =>
    !!firstDate && !!secondDate && isSameDay(firstDate, secondDate)

/**
 * Checks if both arrival and departure dates are provided.
 * @param arrivalDate - The arrival date as a string or Date.
 * @param departureDate - The departure date as a string or Date.
 * @returns True if both dates are provided, otherwise false.
 */
export function hasDatePeriod(
    arrivalDate: string | Date | null | undefined,
    departureDate: string | Date | null | undefined,
): boolean {
    return !!arrivalDate && !!departureDate
}

/**
 * Validates if the arrival and departure dates form a valid period.
 * Supports both string and Date types.
 * @param arrivalDate - The arrival date as a string or Date.
 * @param departureDate - The departure date as a string or Date.
 * @returns True if the period is valid, otherwise false.
 */
export function isValidPeriod(
    arrivalDate: string | Date | null | undefined,
    departureDate: string | Date | null | undefined,
): boolean {
    if (!hasDatePeriod(arrivalDate, departureDate)) {
        return false
    }

    try {
        const parsedArrival = arrivalDate instanceof Date ? arrivalDate : parseISO(arrivalDate as string)
        const parsedDeparture = departureDate instanceof Date ? departureDate : parseISO(departureDate as string)

        return !isAfter(parsedArrival, parsedDeparture) && !isEqual(parsedArrival, parsedDeparture)
    } catch (error) {
        console.error('Error parsing dates:', error)
        return false
    }
}

/**
 * Checks if a date (in string format) is present in an array of Date objects.
 * @param dateString - The date as a string (e.g., "2025-01-24").
 * @param availableDates - An array of Date objects.
 * @returns True if the date is present in the array, otherwise false.
 */
export function isDateInArray(dateString: string | null, availableDates: Date[] | null): boolean {
    if (!dateString || !availableDates) {
        return false
    }
    const targetDate = new Date(dateString)
    return availableDates.some(date => areDatesEqual(date, targetDate))
}

export function calculateDateDifference(
    date1: string | Date | null | undefined,
    date2: string | Date | null | undefined,
): null | number {
    if (!date1 || !date2) {
        return null
    }
    const parsedDate1 = typeof date1 === 'string' ? parseISO(date1) : date1
    const parsedDate2 = typeof date2 === 'string' ? parseISO(date2) : date2
    if (!isValid(parsedDate1) || !isValid(parsedDate2)) {
        return null
    }
    return differenceInDays(parsedDate2, parsedDate1)
}

interface FormatDateRangeParams {
    startDate: string | Date
    endDate: string | Date
    format: FormatDateFunction
}

export const getDateRange = ({ startDate, endDate, format }: FormatDateRangeParams): string => {
    const start = new Date(startDate)
    const end = new Date(endDate)

    return isSameYear(start, end)
        ? `${format(start, 'd MMM')} - ${format(end, 'd MMM yyyy')}`
        : `${format(start, 'd MMM yyyy')} - ${format(end, 'd MMM yyyy')}`
}
