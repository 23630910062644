import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { IntersectionOptions, useInView } from 'react-intersection-observer'
import { ScreenContext } from 'react-components'
import { addMonths, isAfter, startOfDay } from 'date-fns'

import HashSet from '../../../lib/hash-set'
import { CalendarsToShow, DatePickerType } from './types'
import { MAX_CALENDAR_MONTHS } from './constants'
import { getCalendarMonths } from './utils'

/************************ Common hooks. ************************/

/**
 * When `endDate` is not provided or is `undefined`, the DatePicker switches to DatePickerType.SINGLE_DATE.
 *
 * @see DatePickerType.SINGLE_DATE.
 */
export const useDatePickerType = ({ endDate }: { endDate?: Date | null } = {}) => {
    return endDate !== undefined ? DatePickerType.DUAL_DATE : DatePickerType.SINGLE_DATE
}

export const useCalendarMonths = ({
    startDate,
    endDate,
    datePickerType,
}: {
    startDate: Date | null
    endDate?: Date | null
    datePickerType: DatePickerType
}) => {
    const { calendarMonths: _initialCalendarMonths, startMonth: _initialStartMonth } = useMemo(
        () => getCalendarMonths(datePickerType, startDate, endDate),
        [datePickerType, startDate, endDate],
    )

    // Always return the initial start month.
    // Even though it might change, we do not compute it again.
    const startMonth = useMemo(
        () => _initialStartMonth,
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [],
    )

    const [calendarMonths, setCalendarMonths] = useState(_initialCalendarMonths)

    return { calendarMonths, setCalendarMonths, startMonth }
}

export const useEnabledDates = ({
    disabledByDefault,
    enabledDates,
}: {
    disabledByDefault?: boolean
    enabledDates?: Date[]
}) => {
    return useMemo(
        () =>
            disabledByDefault && enabledDates
                ? new HashSet(enabledDates, date => startOfDay(date).toISOString())
                : null,
        [disabledByDefault, enabledDates],
    )
}

export const useDatePicker = ({
    startDate,
    endDate,
    datePickerType,
    onStartDateChange,
    onEndDateChange,
    trackStartDateChange,
    trackEndDateChange,
}: {
    startDate: Date | null
    endDate?: Date | null
    datePickerType: DatePickerType
    onStartDateChange: (date: Date | null) => void
    onEndDateChange?: (date: Date | null) => void
    trackStartDateChange?: (startDate: Date | null, endDate?: Date | null) => void
    trackEndDateChange?: (startDate: Date | null, endDate: Date | null) => void
}) => {
    const _handleSelect = useCallback(
        (date: Date) => {
            if (datePickerType === DatePickerType.DUAL_DATE) {
                if (endDate) {
                    trackStartDateChange?.(date, null)
                    trackEndDateChange?.(date, null)
                    onStartDateChange(date)
                    onEndDateChange?.(null)
                } else if (startDate && isAfter(date, startDate)) {
                    trackEndDateChange?.(startDate, date)
                    onEndDateChange?.(date)
                } else {
                    trackStartDateChange?.(date)
                    onStartDateChange(date)
                }
            } else {
                trackStartDateChange?.(date)
                onStartDateChange(date)
            }
        },
        [
            datePickerType,
            endDate,
            startDate,
            trackStartDateChange,
            onStartDateChange,
            onEndDateChange,
            trackEndDateChange,
        ],
    )

    return {
        onSelect: _handleSelect,
        showRange: datePickerType === DatePickerType.DUAL_DATE,
    }
}

/************************ Modal-specific hooks. ************************/

export const useCalendarObserver = ({
    setCalendarMonths,
    intersectionObserverOptions,
}: {
    setCalendarMonths: Dispatch<SetStateAction<Date[]>>
    intersectionObserverOptions: IntersectionOptions
}) => {
    const [intersectionTargetRef, inView] = useInView(intersectionObserverOptions)
    useEffect(() => {
        if (inView) {
            setCalendarMonths(calendarMonths =>
                calendarMonths.length < MAX_CALENDAR_MONTHS
                    ? [...calendarMonths, addMonths(calendarMonths[calendarMonths.length - 1], 1)]
                    : calendarMonths,
            )
        }
    }, [inView, setCalendarMonths])

    return { intersectionTargetRef }
}

/************************ Widget-specific hooks. ************************/

export const useNumberOfCalendarsToShow = (datePickerType: DatePickerType) => {
    const { isDesktop } = useContext(ScreenContext)
    return datePickerType === DatePickerType.DUAL_DATE && isDesktop ? CalendarsToShow.DUAL : CalendarsToShow.SINGLE
}

export const useCurrentlyShownMonth = ({
    calendarMonths,
    setCalendarMonths,
    startMonth,
    numberOfCalendarsToShow,
}: {
    calendarMonths: Date[]
    setCalendarMonths: Dispatch<SetStateAction<Date[]>>
    startMonth: Date
    numberOfCalendarsToShow: CalendarsToShow
}) => {
    // When showing two calendars, if the `startMonth` is the last one, then we need to pick the second last one.
    const initialCurrentlyShownMonth =
        numberOfCalendarsToShow === CalendarsToShow.DUAL &&
        calendarMonths.length >= 2 &&
        startMonth === calendarMonths[calendarMonths.length - 1]
            ? calendarMonths[calendarMonths.length - 2]
            : startMonth

    const [currentlyShownMonth, setCurrentlyShownMonth] = useState(initialCurrentlyShownMonth)

    const monthsToShow = useMemo(() => {
        const itemIndex = calendarMonths.indexOf(currentlyShownMonth)
        if (itemIndex !== -1) {
            return calendarMonths.slice(
                itemIndex,
                itemIndex + (numberOfCalendarsToShow === CalendarsToShow.DUAL ? 2 : 1),
            )
        }

        // Erroneous case. It should not happen that we cannot find currently shown month in calendar months.
        if (calendarMonths.length > 0) {
            return [calendarMonths[calendarMonths.length - 1]]
        }
        return [currentlyShownMonth]
    }, [calendarMonths, currentlyShownMonth, numberOfCalendarsToShow])

    const showNextMonth = useCallback(() => {
        // Find currently shown month in calendar months.
        const itemIndex = calendarMonths.indexOf(currentlyShownMonth)

        // If found.
        if (itemIndex !== -1) {
            // If there is an available next item, choose that.
            const indexOffset = numberOfCalendarsToShow === CalendarsToShow.DUAL ? 2 : 1
            if (itemIndex + indexOffset < calendarMonths.length) {
                // Here, we do not use index offset as we still only go to the next item, no matter the number of
                // calendars we show.
                setCurrentlyShownMonth(calendarMonths[itemIndex + 1])
            }
            // If there is no available next item, but we can add another calendar month.
            else if (calendarMonths.length < MAX_CALENDAR_MONTHS) {
                const updatedCalendarMonths = [
                    ...calendarMonths,
                    addMonths(calendarMonths[calendarMonths.length - 1], 1),
                ]

                setCalendarMonths(updatedCalendarMonths)
                // Pick the item from the end of the list using index offset.
                setCurrentlyShownMonth(updatedCalendarMonths[updatedCalendarMonths.length - indexOffset])
            }
            // If we cannot add another calendar month, do nothing.
        }
        // Erroneous case. It should not happen that we cannot find currently shown month in calendar months.
        else {
            // Reset currently shown month in this case.
            if (calendarMonths.length > 0) {
                setCurrentlyShownMonth(calendarMonths[0])
            }
        }
    }, [calendarMonths, numberOfCalendarsToShow, currentlyShownMonth, setCalendarMonths])

    const showPreviousMonth = useCallback(() => {
        // Find currently shown month in calendar months.
        const itemIndex = calendarMonths.indexOf(currentlyShownMonth)

        // If found.
        if (itemIndex !== -1) {
            // If there is an available previous item, choose that.
            if (itemIndex - 1 >= 0) {
                setCurrentlyShownMonth(calendarMonths[itemIndex - 1])
            }
            // If there is no available previous item, we cannot simply add another calendar month.
            // Hence, do nothing.
        }
        // Erroneous case. It should not happen that we cannot find currently shown month in calendar months.
        else {
            // Reset currently shown month in this case.
            if (calendarMonths.length > 0) {
                setCurrentlyShownMonth(calendarMonths[0])
            }
        }
    }, [calendarMonths, currentlyShownMonth])

    return { currentlyShownMonth, setCurrentlyShownMonth, showNextMonth, showPreviousMonth, monthsToShow }
}
