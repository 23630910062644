import styled, { css } from 'styled-components'

interface WeekDayCellContainerProps {
    isWithinRange: boolean
    isFirst: boolean
    isLast: boolean
}

export const WeekDayCellContainer = styled.button.attrs({ type: 'button' })<WeekDayCellContainerProps>(
    ({ isWithinRange, isFirst, isLast, theme }) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        position: relative;

        ${isWithinRange &&
        css`
            &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background-color: ${theme.colors.tfwBlueLighter};

                ${(isFirst || isLast) &&
                css`
                    width: calc(50% + 1.25rem);
                `};

                ${isFirst &&
                css`
                    left: unset;
                    border-top-left-radius: 50%;
                    border-bottom-left-radius: 50%;
                `};

                ${isLast &&
                css`
                    right: unset;
                    border-top-right-radius: 50%;
                    border-bottom-right-radius: 50%;
                `};
            }

            &:first-child:before {
                background-image: linear-gradient(to right, ${theme.colors.white}, ${theme.colors.tfwBlueLighter});
            }

            &:last-child:before {
                background-image: linear-gradient(to right, ${theme.colors.tfwBlueLighter}, ${theme.colors.white});
            }
        `};

        &:disabled {
            cursor: not-allowed;
            * {
                color: ${theme.colors.tfwBlack12};
            }
        }
    `,
)

interface WeekDayCellProps {
    isSelected: boolean
    loading?: boolean
}

export const WeekDayCell = styled.time<WeekDayCellProps>(
    ({ isSelected, theme, loading }) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        z-index: 10;
        position: relative; /* Required for positioning the pseudo-element */

        ${isSelected &&
        css`
            background-color: ${theme.colors.tfwBlue12};
            color: ${theme.colors.white};
        `};

        ${loading &&
        css`
            /* Add a pseudo-element for the animated border */
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 2px solid ${theme.colors.tfwBlack10};
                border-radius: 50%;
                animation: border-pulse 1s linear infinite;
            }
        `};

        @keyframes border-pulse {
            0% {
                transform: scale(1);
                opacity: 1;
            }
            100% {
                transform: scale(1.2);
                opacity: 0;
            }
        }
    `,
)
