import { ComponentType, Dispatch, Ref, SetStateAction } from 'react'

import HashSet from '../../../lib/hash-set'

export enum ChooseDate {
    NONE,
    FIRST,
    SECOND,
}

/**
 * Denotes the type of the DatePicker i.e. whether the DatePicker is handling a single date or more dates.
 *
 * Note: This type does not determine how to display the DatePicker i.e. whether to actually show two calendars or one.
 * That functionality is governed by `CalendarsToShow`.
 *
 * @see CalendarsToShow
 */
export enum DatePickerType {
    SINGLE_DATE,
    DUAL_DATE,
}

export enum DatePickerAlignment {
    CENTER,
    RIGHT,
    LEFT,
}

export enum DatePickerMode {
    MODAL = 'modal',
    WIDGET = 'widget',
}

/**
 * Denotes the number of calendars to be shown in certain scenarios.
 *
 * Note: This type does not determine the behavior of DatePicker when it comes to handling single date or more dates.
 * That functionality is governed by `DatePickerType`.
 *
 * @see DatePickerType
 */
export enum CalendarsToShow {
    SINGLE,
    DUAL,
}

export interface DatePickerCommonProps {
    startDate: Date | null
    endDate?: Date | null
    onStartDateChange: (date: Date | null) => void
    onEndDateChange?: (date: Date | null) => void
    trackStartDateChange?: (startDate: Date | null, endDate?: Date | null) => void
    trackEndDateChange?: (startDate: Date | null, endDate: Date | null) => void
    disabledByDefault?: boolean
    enabledDates?: Date[]
    datePrompt?: string
}

/**
 * Extra props that are passed to the common component (modal/widget) by the date-picker.
 */
export interface DatePickerRenderedComponentAddedProps {
    datePickerType: DatePickerType
    calendarMonths: Date[]
    setCalendarMonths: Dispatch<SetStateAction<Date[]>>
    enabledDatesSet: HashSet<Date> | null
    currentlyHoveredDate: Date | null
    setCurrentlyHoveredDate: (date: Date | null) => void
}

export interface DatePickerPassThroughProps extends DatePickerCommonProps {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
    error?: string
    alignmentPosition?: DatePickerAlignment
    showInputInModal?: boolean
    loading?: boolean
}

export interface DatePickerModalProps extends DatePickerRenderedComponentAddedProps, DatePickerPassThroughProps {
    onReset: () => void
    onClear: () => void
    onSubmit: () => void
}

export interface DatePickerWidgetProps extends DatePickerRenderedComponentAddedProps, DatePickerPassThroughProps {
    inputRef: Ref<HTMLElement>
    numberOfCalendarsToShow: CalendarsToShow
    alignmentPosition?: DatePickerAlignment
}

export interface ModalCalendarContainerProps {
    ref?: Ref<HTMLElement>
}

export interface DatePickerExclusiveProps {
    mode: DatePickerMode
    modalComponent: ComponentType<DatePickerModalProps>
    widgetComponent: ComponentType<DatePickerWidgetProps>
    modalCalendarContainerComponent: ComponentType<ModalCalendarContainerProps>
}

export type DatePickerProps = DatePickerPassThroughProps & DatePickerExclusiveProps
