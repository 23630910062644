import { useEffect, useState } from 'react'

import { ChooseDate, DatePickerType } from '../../../../common/date-picker/types'

export const useChooseDate = ({
    startDate,
    endDate,
    datePickerType,
}: {
    startDate: Date | null
    endDate?: Date | null
    datePickerType: DatePickerType
}) => {
    const [chooseDate, setChooseDate] = useState(
        !startDate
            ? ChooseDate.FIRST
            : datePickerType === DatePickerType.DUAL_DATE && !endDate
            ? ChooseDate.SECOND
            : ChooseDate.NONE,
    )

    useEffect(() => {
        if (!startDate) {
            setChooseDate(ChooseDate.FIRST)
        } else if (datePickerType === DatePickerType.DUAL_DATE && !endDate) {
            setChooseDate(ChooseDate.SECOND)
        } else {
            setChooseDate(ChooseDate.NONE)
        }
    }, [startDate, endDate, datePickerType])

    return { chooseDate, setChooseDate }
}
