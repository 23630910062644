import styled, { css, StyledComponent } from 'styled-components'

import { SearchbarWidgetCard, WidgetInput } from '../widget-styles'
import { DefaultTheme } from '../../../../../styled-components'
import { CalendarMonthContainerProps } from '../../../../common/date-picker/widget-styles'
import { DatePickerAlignment } from '../../../../common/date-picker/types'

export const DateInputBackground = styled(WidgetInput as StyledComponent<'div', DefaultTheme>).attrs({ as: 'div' })`
    position: absolute;
    left: 0;
    z-index: -1;
    user-select: none;
`

export const DateInput = styled(WidgetInput).attrs({ readOnly: true, 'aria-readonly': false })`
    user-select: none;
    cursor: pointer;
`

type DatePickerWidgetCardProps = CalendarMonthContainerProps

export const DatePickerWidgetCard = styled(SearchbarWidgetCard)<DatePickerWidgetCardProps>(
    ({ theme, isWide, alignmentPosition = DatePickerAlignment.CENTER }) => css`
        /* Give a min-width as otherwise container collapses due to flex nature of children elements. */
        min-width: 25.5rem; /* 408px */
        left: 50%;
        transform: translateX(-50%);
        padding: ${theme.spacing.large};

        ${isWide &&
        css`
            min-width: 49.5rem; /* 792px */
        `};

        ${alignmentPosition === DatePickerAlignment.RIGHT &&
        css`
            left: auto;
            right: 0;
            transform: none;
        `};
        ${alignmentPosition === DatePickerAlignment.LEFT &&
        css`
            right: auto;
            left: 0;
            transform: none;
        `};
    `,
)
