import styled, { css } from 'styled-components'
import { tabletBreakpoint } from 'react-components'

export const DatePickerPrompt = styled.span(
    ({ theme }) => css`
        flex-shrink: 0;
        padding: ${theme.spacing.xxSmall} ${theme.spacing.xSmall};
        font-size: ${theme.typography.font.size.small};
        line-height: ${theme.typography.font.size.xLarge};
        background-color: ${theme.colors.tfwWhite64};
        border-radius: ${theme.typography.border.radius.xSmall};
        user-select: none;

        @media only screen and (min-width: ${tabletBreakpoint.min}px) and (max-width: ${tabletBreakpoint.max}px) {
            font-size: ${theme.typography.font.size.xSmall};
            line-height: ${theme.typography.font.size.medium};
            letter-spacing: 0.0125rem;
        }
    `,
)
