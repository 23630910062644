import { Location } from '../types'
import { format } from '../../../../hooks/date'
import { DatePickerType } from '../../../common/date-picker/types'

// Return placeholder in case of falsy search string.
export const getLocationPickerLabel = (location: Location, locationPlaceholder: string) =>
    location.search || locationPlaceholder

export const getDatePickerStartDateLabel = ({
    startDate,
    endDate,
    startDatePlaceholder,
    datePickerType,
}: {
    startDate: Date | null
    endDate?: Date | null
    startDatePlaceholder: string
    datePickerType: DatePickerType
}) =>
    startDate
        ? format(
              startDate,
              datePickerType !== DatePickerType.DUAL_DATE ||
                  (endDate && endDate.getFullYear() !== startDate.getFullYear())
                  ? 'dd MMM yyyy'
                  : 'dd MMM',
          )
        : startDatePlaceholder

export const getDatePickerEndDateLabel = ({
    endDate,
    endDatePlaceholder,
}: {
    endDate: Date | null
    endDatePlaceholder: string
}) => (endDate ? format(endDate, 'dd MMM yyyy') : endDatePlaceholder)

export const getShouldShowEndDateLabel = (
    datePickerType: DatePickerType,
    endDate?: Date | null,
    endDatePlaceholder?: string,
) => datePickerType === DatePickerType.DUAL_DATE && endDate !== undefined && endDatePlaceholder !== undefined

export const getDatePickerLabel = ({
    startDate,
    endDate,
    startDatePlaceholder,
    endDatePlaceholder,
    datePickerType,
}: {
    startDate: Date | null
    endDate?: Date | null
    startDatePlaceholder: string
    endDatePlaceholder?: string
    datePickerType: DatePickerType
}) => {
    const startDateString = getDatePickerStartDateLabel({
        startDate,
        endDate,
        startDatePlaceholder,
        datePickerType,
    })

    if (getShouldShowEndDateLabel(datePickerType, endDate, endDatePlaceholder)) {
        const endDateString = getDatePickerEndDateLabel({
            endDate: endDate!,
            endDatePlaceholder: endDatePlaceholder!,
        })

        return `${startDateString} - ${endDateString}`
    }

    return startDateString
}

export const getGuestPickerLabel = (guests: number, guestText: string, guestsText: string, guestsPlaceholder: string) =>
    guests && guests > 0 ? `${guests} ${guests > 1 ? guestsText : guestText}` : guestsPlaceholder
