import React, { FC } from 'react'
import { isSameMonth } from 'date-fns'

import { useOnClickOutside } from '../../../hooks/dom'
import {
    CalendarsToShow,
    DatePickerAlignment,
    DatePickerExclusiveProps,
    DatePickerPassThroughProps,
    DatePickerRenderedComponentAddedProps,
} from './types'
import { useDateFormatter } from '../../../hooks/date'
import { useCurrentlyShownMonth, useDatePicker, useNumberOfCalendarsToShow } from './hooks'
import Calendar from '../calendar'
import { firstDayOfWeek, MAX_CALENDAR_MONTHS } from './constants'
import {
    ArrowButton,
    ArrowLeft,
    ArrowRight,
    CalendarCardHeader,
    CalendarCardHeaderContainer,
    CalendarMonthContainer,
    DatePickerWidgetPrompt,
    MonthTitle,
    WidgetContent,
} from './widget-styles'
import { useTranslation } from '../../../hooks/locale'
import { getDatePrompt } from './utils'

type Props = DatePickerRenderedComponentAddedProps &
    DatePickerPassThroughProps & {
        component: DatePickerExclusiveProps['widgetComponent']
        startMonth: Date
    }

const Widget: FC<Props> = ({ component: Component, startMonth, datePrompt, ...restProps }) => {
    const {
        onClose,
        startDate,
        endDate,
        onStartDateChange,
        onEndDateChange,
        trackStartDateChange,
        trackEndDateChange,
        disabledByDefault,
        datePickerType,
        calendarMonths,
        setCalendarMonths,
        enabledDatesSet,
        currentlyHoveredDate,
        setCurrentlyHoveredDate,
        loading,
    } = restProps

    const { format } = useDateFormatter()
    const { t } = useTranslation()

    const numberOfCalendarsToShow = useNumberOfCalendarsToShow(datePickerType)

    const { monthsToShow, showNextMonth, showPreviousMonth } = useCurrentlyShownMonth({
        calendarMonths,
        setCalendarMonths,
        startMonth,
        numberOfCalendarsToShow,
    })

    const { onSelect, showRange } = useDatePicker({
        startDate,
        endDate,
        datePickerType,
        onStartDateChange,
        onEndDateChange,
        trackStartDateChange,
        trackEndDateChange,
    })

    const { ref: _widgetInputContainerRef } = useOnClickOutside(onClose)
    const _isWide = numberOfCalendarsToShow === CalendarsToShow.DUAL
    const _datePrompt =
        datePrompt ?? getDatePrompt(datePickerType, startDate, endDate || currentlyHoveredDate, false, t)

    return (
        <Component
            {...restProps}
            datePrompt={_datePrompt}
            inputRef={_widgetInputContainerRef}
            numberOfCalendarsToShow={numberOfCalendarsToShow}
        >
            {_isWide && <DatePickerWidgetPrompt isCentered>{_datePrompt}</DatePickerWidgetPrompt>}
            <WidgetContent>
                {monthsToShow.map(month => (
                    <CalendarMonthContainer key={month.toISOString()} isWide={_isWide}>
                        <CalendarCardHeaderContainer>
                            <ArrowButton onClick={showPreviousMonth} disabled={isSameMonth(month, calendarMonths[0])}>
                                <ArrowLeft />
                            </ArrowButton>
                            <CalendarCardHeader>
                                <MonthTitle>{format(month, 'MMMM yyyy')}</MonthTitle>
                                {!_isWide && <DatePickerWidgetPrompt>{_datePrompt}</DatePickerWidgetPrompt>}
                            </CalendarCardHeader>
                            <ArrowButton
                                onClick={showNextMonth}
                                disabled={
                                    calendarMonths.length === MAX_CALENDAR_MONTHS &&
                                    isSameMonth(month, calendarMonths[calendarMonths.length - 1])
                                }
                            >
                                <ArrowRight />
                            </ArrowButton>
                        </CalendarCardHeaderContainer>
                        <Calendar
                            month={month}
                            showMonthInBody={false}
                            showDaysOfWeekGrid
                            firstDayOfWeek={firstDayOfWeek}
                            startDate={startDate}
                            endDate={endDate}
                            showRange={showRange}
                            currentlyHoveredDate={currentlyHoveredDate}
                            setCurrentlyHoveredDate={setCurrentlyHoveredDate}
                            onSelect={onSelect}
                            disabledByDefault={disabledByDefault}
                            enabledDates={enabledDatesSet}
                            loading={loading}
                        />
                    </CalendarMonthContainer>
                ))}
            </WidgetContent>
        </Component>
    )
}

export default Widget
