import styled, { css } from 'styled-components'
import { tabletBreakpoint } from 'react-components'

export const Container = styled.div(
    ({ theme }) => css`
        width: ${theme.layout.fill};
        text-align: center;
        user-select: none;
    `,
)

export const CalendarGrid = styled.div(
    ({ theme }) => css`
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        row-gap: ${theme.spacing.medium};
        max-width: 21.875rem;
        margin: 0 auto;
    `,
)

export const CalendarMonthViewContainer = styled.div(
    ({ theme }) => css`
        font-size: ${theme.typography.font.size.medium};
        line-height: ${theme.typography.font.size.xLarge};
        margin-bottom: ${theme.spacing.medium};

        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            margin: ${theme.spacing.medium} 0 0;
        }
    `,
)

export const Month = styled.span(
    ({ theme }) => css`
        display: block;
        text-align: center;
        padding: ${theme.spacing.medium};
    `,
)
