import styled from 'styled-components'

import { FullScreenModalBody } from '../../../../common/modal/full-screen-modal'

export const DatePickerModalBody = styled(FullScreenModalBody)`
    padding-bottom: 3.5625rem; /* = 57px. Give an offset in order to make content beneath footer visible. */
    margin-bottom: -3.5625rem;
`

export const DatePickerModalCalendarContainer = styled.div`
    padding-top: 10.25rem; /* 164px. Offset to help in scrolling. */
    margin-top: -10.25rem;
`
