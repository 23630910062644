import React, { FC, useEffect, useRef } from 'react'
import { isSameMonth } from 'date-fns'

import { DatePickerExclusiveProps } from './types'
import Calendar, { CalendarProps } from '../calendar'

export interface ModalCalendarProps extends CalendarProps {
    component: DatePickerExclusiveProps['modalCalendarContainerComponent']
}

const ModalCalendar: FC<ModalCalendarProps> = ({ component: CalendarContainer, ...restProps }) => {
    const { startDate, month } = restProps

    const _containerRef = useRef<HTMLElement>(null)

    // Scroll to this month, on mount, if the `startDate` falls in this month.
    useEffect(() => {
        if (startDate && isSameMonth(startDate, month)) {
            _containerRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
        // Disable rules here as we only just want to run this effect on mount.
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    return (
        <CalendarContainer ref={_containerRef}>
            <Calendar {...restProps} />
        </CalendarContainer>
    )
}

export default ModalCalendar
