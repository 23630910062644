import { desktopBreakpoint, SolidButton, tabletBreakpoint } from 'react-components'
import styled, { css } from 'styled-components'

export const SearchbarContainer = styled.div(
    ({ theme }) => css`
        flex-grow: 1;
        margin: 0 ${theme.spacing.small};
        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            margin: 0 1.25rem;
            display: flex;
        }

        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            margin: 0 ${theme.spacing.large};
        }
    `,
)

export const SearchbarContent = styled.form<{ maxWidth?: string }>`
    max-width: ${({ maxWidth }) => maxWidth || 'calc(100vw - 6.5rem)'};
    @media only screen and (min-width: ${tabletBreakpoint.min}px) {
        width: 100%;
        max-width: ${({ maxWidth }) => maxWidth || '36rem'};
        margin: 0 auto;
        display: grid;
        grid-template-columns: 4fr 5fr 3fr minmax(3.5rem, auto);
    }

    @media only screen and (min-width: ${desktopBreakpoint.min}px) {
        max-width: ${({ maxWidth }) => maxWidth || '38.75rem'};
    }
`

export const AllInputsDisplayBar = styled.div.attrs({ role: 'button' })(
    ({ theme }) => css`
        border: ${theme.typography.border.width.xxxThin} solid ${theme.colors.tfwBlackOpacity10};
        border-radius: ${theme.typography.border.radius.small};
        padding: 0.6875rem ${theme.spacing.small}; /* 0.6875rem = 11px. One less pixel at the top to accommodate the border. */
        text-align: center;
        overflow: hidden;
        font-size: ${theme.typography.font.size.xSmall};
        line-height: ${theme.typography.font.size.medium};
        letter-spacing: 0.0125rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
    `,
)

export const SubmitButton = styled(SolidButton).attrs(({ theme, padding }) => ({
    forwardedAs: 'button',
    type: 'button',
    width: theme.layout.fill,
    padding: padding ?? `${theme.spacing.xSmall} ${theme.spacing.small}`,
}))(
    ({ theme }) => css`
        font: ${theme.typography.font.lato.weight.bold} ${theme.typography.font.size.small} /
            ${theme.typography.font.size.xLarge} ${theme.typography.font.family.latoVerdana};
    `,
)

export const SearchButton = styled(SubmitButton).attrs({ borderRadius: '0' })(
    ({ theme }) => css`
        /* Remove the unnecessary height due to button height being greater than content height. */
        /* Read more: https://stackoverflow.com/a/45424887/8158281 */
        & * {
            display: block;
        }

        &:last-child {
            border-top-right-radius: ${theme.typography.border.radius.small};
            border-bottom-right-radius: ${theme.typography.border.radius.small};
        }

        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            min-width: 6.25rem; /* 100px */
        }
    `,
)
