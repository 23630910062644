import React, { FC } from 'react'

import { CalendarMonthViewContainer, Container, Month } from './styles'
import { useDateFormatter } from '../../../hooks/date'
import CalendarMonthView from './calendar-month-view'
import { Day } from './types'
import CalendarDaysOfWeekGrid from './calendar-days-of-week-grid'
import HashSet from '../../../lib/hash-set'

export interface CalendarProps {
    firstDayOfWeek?: Day
    showMonthInBody?: boolean
    showDaysOfWeekGrid?: boolean
    weekDayGridOffset?: number
    startDate: Date | null
    endDate?: Date | null
    showRange?: boolean
    currentlyHoveredDate: Date | null
    setCurrentlyHoveredDate: (date: Date | null) => void
    month: Date
    onSelect: (date: Date) => void
    disabledByDefault?: boolean
    enabledDates: HashSet<Date> | null
    loading?: boolean
}

const Calendar: FC<CalendarProps> = ({
    firstDayOfWeek,
    showMonthInBody = false,
    showDaysOfWeekGrid = true,
    startDate,
    endDate,
    showRange = false,
    currentlyHoveredDate,
    setCurrentlyHoveredDate,
    weekDayGridOffset,
    month,
    onSelect,
    disabledByDefault,
    enabledDates,
    loading,
}) => {
    const { format } = useDateFormatter()

    return (
        <Container>
            {showDaysOfWeekGrid && (
                <CalendarDaysOfWeekGrid firstDayOfWeek={firstDayOfWeek} offset={weekDayGridOffset} />
            )}
            <CalendarMonthViewContainer>
                {showMonthInBody && <Month>{format(month, 'MMMM yyyy')}</Month>}
                <CalendarMonthView
                    month={month}
                    firstDayOfWeek={firstDayOfWeek}
                    startDate={startDate}
                    endDate={endDate}
                    showRange={showRange}
                    currentlyHoveredDate={currentlyHoveredDate}
                    setCurrentlyHoveredDate={setCurrentlyHoveredDate}
                    onSelect={onSelect}
                    disabledByDefault={disabledByDefault}
                    enabledDates={enabledDates}
                    loading={loading}
                />
            </CalendarMonthViewContainer>
        </Container>
    )
}

export default Calendar
