import React, { FC, memo, useMemo } from 'react'
import { isBefore, startOfToday } from 'date-fns'

import { MonthCalendarGrid } from './calendar-month-view-styles'
import CalendarWeekDayCell from './calendar-week-day-cell'
import HashSet from '../../../lib/hash-set'
import { getDateRangeAttributes, getDaysOfMonth, getStartColumnForMonth } from './utils'
import { areDatesEqual } from '../../../utils/date-utils'
import { Day } from './types'

interface Props {
    firstDayOfWeek?: Day
    month: Date
    startDate: Date | null
    endDate?: Date | null
    showRange: boolean
    currentlyHoveredDate: Date | null
    setCurrentlyHoveredDate: (date: Date | null) => void
    onSelect: (date: Date) => void
    disabledByDefault?: boolean
    enabledDates: HashSet<Date> | null
    loading?: boolean
}

const CalendarMonthView: FC<Props> = ({
    month,
    firstDayOfWeek,
    startDate,
    endDate,
    showRange,
    currentlyHoveredDate,
    setCurrentlyHoveredDate,
    onSelect,
    disabledByDefault,
    enabledDates,
    loading,
}) => {
    const _daysOfMonth = useMemo(() => getDaysOfMonth(month), [month])
    const _startColumn = getStartColumnForMonth(month, firstDayOfWeek)

    const _currentTime = startOfToday()

    return (
        <MonthCalendarGrid startAt={_startColumn}>
            {_daysOfMonth.map(day => {
                const { isFirst, isLast, isWithinRange } = getDateRangeAttributes(
                    showRange,
                    day,
                    startDate,
                    endDate || currentlyHoveredDate,
                )

                return (
                    <CalendarWeekDayCell
                        key={day.toISOString()}
                        day={day}
                        disabled={isBefore(day, _currentTime) || (!!disabledByDefault && !enabledDates?.has(day))}
                        isSelected={
                            areDatesEqual(startDate, day) ||
                            areDatesEqual(endDate, day) ||
                            areDatesEqual(currentlyHoveredDate, day)
                        }
                        onClick={onSelect}
                        onHover={setCurrentlyHoveredDate}
                        isWithinRange={isWithinRange}
                        isFirstWithinRange={isFirst}
                        isLastWithinRange={isLast}
                        loading={loading}
                    />
                )
            })}
        </MonthCalendarGrid>
    )
}

export default memo(CalendarMonthView)
