import styled, { css } from 'styled-components'

export interface CalendarHeadProps {
    $offset?: number
    zIndex?: number
}

export const CalendarHead = styled.div<CalendarHeadProps>(
    ({ $offset, zIndex, theme }) => css`
        ${!!$offset &&
        css`
            position: sticky;
            top: ${$offset}px;
        `};
        ${zIndex !== undefined && `z-index: ${zIndex}`};
        text-align: center;
        background-color: ${theme.colors.white};
        padding: ${theme.spacing.medium} 0;
        border-bottom: ${theme.typography.border.width.xxxThin} solid ${theme.colors.tfwBlackOpacity10};
        user-select: none;
    `,
)

export const DayOfWeek = styled.div(
    ({ theme }) => css`
        font-size: 0.8125rem;
        line-height: ${theme.typography.font.size.medium};
        letter-spacing: 0.0125rem;
        color: ${theme.colors.tfwBlackOpacity50};
    `,
)
