import {
    eachDayOfInterval,
    endOfDay,
    endOfMonth,
    getDay,
    isBefore,
    isWithinInterval,
    startOfDay,
    startOfMonth,
} from 'date-fns'

import { areDatesEqual } from '../../../utils/date-utils'
import { DAYS, DEFAULT_FIRST_DAY_OF_WEEK } from './constants'

export const getDaysOfMonth = (month: Date) => eachDayOfInterval({ start: startOfMonth(month), end: endOfMonth(month) })

export const getStartColumnForMonth = (month: Date, firstDayOfWeek: number = DEFAULT_FIRST_DAY_OF_WEEK) => {
    const dayOfWeek = getDay(startOfMonth(month))
    return ((7 - firstDayOfWeek + dayOfWeek) % 7) + 1
}

export const getDateRangeAttributes = (
    computeRange: boolean,
    date: Date,
    firstDate: Date | null,
    secondDate: Date | null = null,
) => {
    if (!computeRange) {
        return { isWithinRange: false, isFirst: false, isLast: false }
    }

    const _startOfFirstDate = firstDate && startOfDay(firstDate)
    const _endOfSecondDate = secondDate && endOfDay(secondDate)

    const isWithinRange =
        !!_startOfFirstDate &&
        !!_endOfSecondDate &&
        isBefore(_startOfFirstDate, _endOfSecondDate) &&
        isWithinInterval(date, {
            start: _startOfFirstDate,
            end: _endOfSecondDate,
        })

    const isFirst = isWithinRange && areDatesEqual(date, firstDate)
    const isLast = isWithinRange && areDatesEqual(date, secondDate)

    return { isWithinRange, isFirst, isLast }
}

export const getDaysOfWeek = (firstDayOfWeek: Day = DEFAULT_FIRST_DAY_OF_WEEK) =>
    firstDayOfWeek ? [...DAYS.slice(firstDayOfWeek), ...DAYS.slice(0, firstDayOfWeek)] : DAYS
